const fetchMap = async mapData => {
  return Promise.resolve({
    type: "FeatureCollection",
    features: mountMapboxObject(mapData)
  });
};

const mountMapboxObject = json => {
  let featuresList = [];
  for (let index = 0; index < json.length; index++) {
    const element = json[index];
    featuresList.push({
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [
          parseFloat(element.longitude),
          parseFloat(element.latitude)
        ]
      },
      properties: {
        id: parseInt(element.id),
        nome: element.empresa,
        fundacao: element.fundacao,
        tipo: element.tipo,
        porte: element.porte,
        setor: element.setor,
        setor_sec: element.setor_sec,
        setor_terc: element.setor_terc,
        regiao: element.regiao,
        estado: element.estado,
        cidade: element.cidade,
        endereco: element.endereco,
        website: element.website,
        descricao: element.descricao
      }
    });
  }
  return featuresList;
};

export default fetchMap;
