import React, {useState} from 'react';

import './modal.css';

export default function Modal() {
  const [showModal, setShowModal] = useState(true);

  return (
    <div>
      {showModal && (
        <div className="modal">
          <div className="modal-main">
            <p>Seja bem-vindo ao Mapa Biotec, um mapeamento de empresas de biotecnologia do Brasil realizado pelo Profissão Biotec. </p>
            <p>O mapa é gratuito, aberto e colaborativo. Caso você veja alguma informação incorreta ou ausente, pedimos que você nos informe através do botão “Edite o Mapa”. As alterações serão avaliadas e adicionadas ao mapa.</p>
            <p>Bom uso!</p>
            <button type="button" className="closeModal" onClick={() => setShowModal(false)}>Fechar!</button>
          </div>
        </div>
      )}
    </div>
  )
}