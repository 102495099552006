import React from "react";

import "./popup.css";

const Popup = ({ feature }) => {
  const {
    id,
    nome,
    fundacao,
    tipo,
    porte,
    setor,
    setor_sec,
    setor_terc,
    estado,
    cidade,
    endereco,
    website,
    descricao
  } = feature.properties;
  const setorText = () => {
    let text = setor;
    if (setor_sec.length > 0) {
      text += ` / ${setor_sec}`;
    }
    if (setor_terc.length > 0) {
      text += ` / ${setor_terc}`;
    }
    return text;
  };

  return (
    <div className="popupContent" id={`popup-${id}`}>
      <h3>
        {nome} 
      </h3>
      <div className="item">
      <p>{descricao}</p>
      </div>
      <div className="item">
        <p className="title">Setores</p>
        <p>{setorText()}</p>
      </div>
      <div className="item">
        <p className="title">Tipo de empresa</p>
        <p>{tipo}</p>
      </div>
      <div className="item">
        <p className="title">porte</p>
        <p>{porte}</p>
      </div>
      <div className="item">
        <p className="title">localização</p>
        <p>
          {endereco} - {cidade}/{estado}
        </p>
      </div>
      <div className="item">
        <a href={website} target="_blank" rel="external">Acesse o site</a>
      </div>
    </div>
  );
};

export default Popup;
