import React, { useEffect, useState } from "react";

import diBlasi from "../image/AF_Di-Blasi_Baseimagens-01.png";
import eppendorf2 from "../image/eppendorf-banner-2.jpg";
import sidedoor from "../image/sidedoor.png";
import thermo from "../image/thermo.jpeg";

import "./ads.css";

export default function Ads(props) {
  const [activeCarousel, setActiveCarousel] = useState(1);
  const carouselTotal = 4;

  useEffect(() => {
    startCarousel();
  });

  const startCarousel = () => {
    setTimeout(() => {
      if (activeCarousel < carouselTotal) {
        setActiveCarousel(activeCarousel + 1);
      } else {
        setActiveCarousel(1);
      }
    }, 5000);
  };

  return (
    <div id="partners-carousel">
      {activeCarousel === 1 && (
        <a
          href="https://online-shop.eppendorf.com.br/BR-pt/Agitadores-de-laboratorio-44544/Shakers-incubadores-44545/InnovaS44i-PF-169613.html?utm_source=Mapa%20Profissao%20biotec&utm_medium=banner&utm_campaign=mapa_profissao_biotec"
          target="_blank"
          rel="external"
        >
          <img src={eppendorf2} className="carousel-img" alt="Eppendorf" />
        </a>
      )}
      {activeCarousel === 2 && (
        <a href="https://diblasiparente.com.br/" target="_blank" rel="external">
          <img src={diBlasi} className="carousel-img" alt="Di Blasi" />
        </a>
      )}
      {activeCarousel === 3 && (
        <a href="https://sidedoor.com.br" target="_blank" rel="external">
          <img src={sidedoor} className="carousel-img" alt="Sidedoor" />
        </a>
      )}
      {activeCarousel === 4 && (
        <a
          href="https://www.thermofisher.com/br/en/home.html"
          target="_blank"
          rel="external"
        >
          <img src={thermo} className="carousel-img" alt="Sidedoor" />
        </a>
      )}
    </div>
  );
}
