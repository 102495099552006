import React, { useState, useEffect } from "react";
import InputRange from "react-input-range";
import moment from "moment";

import "./index.css";
import "./input-range.css";
import logo from "../image/logo_full_branco.png";
import banner from "../image/logo_branco.png";
import Ads from "../components/Ads";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faIndustry,
  faMapMarker,
  faCaretRight,
  faCaretLeft,
  faChevronDown,
  faChevronUp
} from "@fortawesome/free-solid-svg-icons";

const minDate = new Date(1500, 0, 1);
const initFilter = {
  nome: "",
  porte: "",
  setor: "",
  tipo: "",
  regiao: "",
  ano: {
    min: moment(minDate).format(),
    max: moment()
      .startOf("year")
      .format()
  },
  cidade: "",
  estado: "",
  estado_id: ""
};

const Filter = props => {
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [regionFilterDetails, setRegionFilterDestails] = useState(true);
  const [orgFilterDetails, setOrgFilterDestails] = useState(true);
  const [collapsed, setCollapsed] = useState(false);
  const [filter, setFilter] = useState(initFilter);

  useEffect(() => {
    fetch(
      "https://servicodados.ibge.gov.br/api/v1/localidades/estados?orderBy=nome"
    )
      .then(res => res.json())
      .then(result => {
        setStates(result);
      });
  }, []);

  const watchStateChange = uf => {
    if (uf.target.selectedOptions[0].text === "UF") {
      setFilter({
        ...filter,
        estado: "",
        estado_id: ""
      });
    } else {
      setFilter({
        ...filter,
        cidade: "",
        estado: uf.target.selectedOptions[0].text,
        estado_id: uf.target.value
      });
    }
    fetch(
      `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${uf.target.value}/municipios`
    )
      .then(res => res.json())
      .then(result => {
        setCities(result);
      });
  };

  return (
    <div className="mainFilter">
      {!collapsed && (
        <div className="filterWrapper">
          <div className="filterHeader">
            <div>
              <img src={logo} className="logo" alt="Biotech Logo" />
            </div>
            <div>
              <a
                href="http://mapa.profissaobiotec.com.br/projeto/"
                target="_blank"
                rel="external"
              >
                Conheça o projeto
              </a>
            </div>
          </div>
          <div className="filterContent">
            <div
              id="orgFilter"
              className="filterTitle"
              onClick={() => setOrgFilterDestails(!orgFilterDetails)}
            >
              <div className="filterTitleName">
                <FontAwesomeIcon icon={faIndustry} />
                <h3>Organização</h3>
              </div>
              <div className="filterTitleChevron">
                {orgFilterDetails ? (
                  <FontAwesomeIcon icon={faChevronUp} />
                ) : (
                  <FontAwesomeIcon icon={faChevronDown} />
                )}
              </div>
            </div>
            {orgFilterDetails && (
              <div>
                {/* <div>
                  <h4 style={{ marginTop: 0 }}>Ano de fundação</h4>
                  <div className="yearSelector">
                    <InputRange
                      maxValue={2020}
                      minValue={1920}
                      value={{
                        min: parseInt(moment(filter.ano.min).format("Y")),
                        max: parseInt(moment(filter.ano.max).format("Y"))
                      }}
                      onChange={year => {
                        const min = moment(new Date(year.min, 0, 1)).format();
                        const max = moment(new Date(year.max, 0, 1)).format();
                        setFilter({ ...filter, ano: { min, max } });
                      }}
                    />
                  </div>
                </div> */}

                <div>
                  <h4>Nome da organização</h4>
                  <input
                    type="text"
                    className="inputCustom"
                    onChange={value =>
                      setFilter({
                        ...filter,
                        nome: value.target.value
                      })
                    }
                    value={filter.nome}
                    placeholder="Digite um nome para pesquisar"
                  ></input>
                </div>
                <div>
                  <h4>Tipo de organização</h4>
                  <select
                    className="inputCustom"
                    onChange={value =>
                      setFilter({
                        ...filter,
                        tipo: value.target.value
                      })
                    }
                    value={filter.tipo}
                  >
                    <option value="">Selecione um tipo</option>
                    <option value="Startup">Startup</option>
                    <option value="Empresa Nacional">Empresa Nacional</option>
                    <option value="Empresa Multinacional">
                      Empresa Multinacional
                    </option>
                  </select>
                </div>
                {/* <div>
                  <h4 className="">Porte</h4>
                  <select
                    className="inputCustom"
                    onChange={value =>
                      setFilter({
                        ...filter,
                        porte: value.target.value
                      })
                    }
                    value={filter.porte}
                  >
                    <option value="">Selecione o porte</option>
                    <option value="Micro: Até 19 empregados">
                      Micro: Até 19 empregados
                    </option>
                    <option value="Pequena: De 20 a 99 empregados">
                      Pequena: De 20 a 99 empregados
                    </option>
                    <option value="Média: De 100 a 499 empregados">
                      Média: De 100 a 499 empregados
                    </option>
                    <option value="Grande: 500 ou mais empregados">
                      Grande: 500 ou mais empregados
                    </option>
                  </select>
                </div> */}
                <div>
                  <h4 className="">Setor principal</h4>
                  <select
                    className="inputCustom"
                    onChange={value =>
                      setFilter({
                        ...filter,
                        setor: value.target.value
                      })
                    }
                    value={filter.setor}
                  >
                    <option value="">Selecione o setor</option>
                    <option value="Agricultura">Agricultura</option>
                    <option value="Biotecnologia e Saúde Animal">
                      Biotecnologia e Saúde Animal
                    </option>
                    <option value="Saúde Humana e Bem-estar">
                      Saúde Humana e Bem-estar
                    </option>
                    <option value="Meio Ambiente">Meio Ambiente</option>
                    <option value="Biotecnologia de Alimentos">
                      Biotecnologia de Alimentos
                    </option>
                    <option value="Biotecnologia Industrial e Bioprocessos">
                      Biotecnologia Industrial e Bioprocessos
                    </option>
                    <option value="Insumos">Insumos</option>
                    <option value="Outras áreas">Outras áreas</option>
                  </select>
                </div>
              </div>
            )}
            <div
              id="regionFilter"
              className="filterTitle"
              onClick={() => setRegionFilterDestails(!regionFilterDetails)}
            >
              <div className="filterTitleName">
                <FontAwesomeIcon icon={faMapMarker} />
                <h3>Localização</h3>
              </div>
              <div className="filterTitleChevron">
                {regionFilterDetails ? (
                  <FontAwesomeIcon icon={faChevronUp} />
                ) : (
                  <FontAwesomeIcon icon={faChevronDown} />
                )}
              </div>
            </div>
            {regionFilterDetails && (
              <div id="regionDetails">
                <div>
                  <h4 style={{ marginTop: 0 }}>Região</h4>
                  <select
                    className="inputCustom"
                    onChange={value =>
                      setFilter({
                        ...filter,
                        regiao: value.target.value
                      })
                    }
                    value={filter.regiao}
                  >
                    <option value="">Selecione a região</option>
                    <option value="Norte">Norte</option>
                    <option value="Nordeste">Nordeste</option>
                    <option value="Centro-oeste">Centro Oeste</option>
                    <option value="Sudeste">Sudeste</option>
                    <option value="Sul">Sul</option>
                  </select>
                </div>
                <div className="citySelect">
                  <div className="stateDiv">
                    <h4 className="">Estado</h4>
                    <select
                      className="inputCustom"
                      onChange={value => watchStateChange(value)}
                      value={filter.estado_id}
                    >
                      <option value="false">UF</option>
                      {states.map(state => (
                        <option key={state.id} value={state.id}>
                          {state.sigla}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="cityDiv">
                    <h4 className="">Cidade</h4>
                    <select
                      className="inputCustom"
                      onChange={value =>
                        setFilter({ ...filter, cidade: value.target.value })
                      }
                      value={filter.cidade}
                    >
                      <option value="">Selecione a cidade</option>
                      {cities.map(city => (
                        <option key={city.id} value={city.nome}>
                          {city.nome}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="filterFooter">
            <div className="buttonWrapper">
              <button
                type="button"
                className="filterButton"
                onClick={() => props.filterAction(filter)}
              >
                Pesquisar
              </button>
            </div>
            <div className="footerLinks">
              <a
                href="https://forms.gle/AzwJ5wM8C7BMC9JA8"
                target="_blank"
                rel="external"
              >
                Edite o mapa
              </a>
              <a
                href="https://datastudio.google.com/reporting/d8304de9-adf1-4067-94b5-128dd4cc775c"
                target="_blank"
                rel="external"
              >
                Dados do mapa
              </a>
            </div>
            <Ads />
          </div>
          <div
            className="collapseButton"
            onClick={() => setCollapsed(!collapsed)}
          >
            <FontAwesomeIcon icon={faCaretLeft} />
          </div>
        </div>
      )}
      {collapsed && (
        <div className="filterCollapsed">
          <div
            className="collapsedButton"
            onClick={() => setCollapsed(!collapsed)}
          >
            <FontAwesomeIcon icon={faCaretRight} />
          </div>
        </div>
      )}
    </div>
  );
};

export default Filter;
