import ReactDOM from "react-dom";
import React, { useRef, useEffect, useState } from "react";
import mapboxgl from "mapbox-gl";

import fetchMap from "../api/fetchMap";
import Popup from "../components/Popup";
import "./index.css";

import icon from "../image/location-pin.png";
// import icon from "../image/flask.png";
import Filter from "../filter";
import Modal from "../modal";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

const MapBox = () => {
  const mapContainerRef = useRef(null);
  const popUpRef = useRef(new mapboxgl.Popup({ offset: 15 }));
  const [stateMap, setStateMap] = useState();
  const [noResultsModal, setNoResultsModal] = useState(false);

  // initialize map when component mounts
  useEffect(() => {
    fetch("https://www.mapa.profissaobiotec.com.br/api/get-info.php")
      .then(res => res.json())
      .then(
        result => {
          console.log(result);
          mapInitializer(result);
        },
        error => {
          console.log(error);
        }
      );
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const mapInitializer = mapData => {
    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      // See style options here: https://docs.mapbox.com/api/maps/#styles
      style: "mapbox://styles/mapbox/light-v10",
      center: [-65.968944, -15.111324],
      zoom: 3.7
    });
    setStateMap(map);

    // add navigation control (zoom buttons)
    map.addControl(new mapboxgl.NavigationControl(), "bottom-right");

    map.on("load", () => {
      // add the data source for new a feature collection with no features
      map.addSource("industry-data", {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features: []
        },
        cluster: true,
        clusterMaxZoom: 17, // Max zoom to cluster points on
        clusterRadius: 50 // Radius of each cluster when clustering points (defaults to 50)
      });
      // now add the layer, and reference the data source above by name
      map.addLayer({
        id: "industry-pointer",
        source: "industry-data",
        type: "circle",
        filter: ["has", "point_count"],
        paint: {
          "circle-color": [
            "step",
            ["get", "point_count"],
            "#f28cb1",
            3,
            "#f28cb1",
            10,
            "#f28cb1"
          ],
          "circle-radius": ["step", ["get", "point_count"], 15, 5, 30, 10, 40]
        }
      });
      map.addLayer({
        id: "cluster-count",
        type: "symbol",
        source: "industry-data",
        filter: ["has", "point_count"],
        layout: {
          "text-field": "{point_count_abbreviated}",
          "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
          "text-size": 12
        }
      });

      map.loadImage(icon, function(error, image) {
        map.addImage("marker", image);
      });

      map.addLayer({
        id: "unclustered-point",
        type: "symbol",
        source: "industry-data",
        filter: ["!", ["has", "point_count"]],
        layout: {
          "icon-image": "marker",
          "icon-size": 0.35
        }
      });
    });

    map.on("load", () => loadMapMarkers(map, mapData));

    // change cursor to pointer when user hovers over a clickable feature
    map.on("mouseenter", "industry-pointer", e => {
      if (e.features.length) {
        map.getCanvas().style.cursor = "pointer";
      }
    });

    // reset cursor to default when user is no longer hovering over a clickable feature
    map.on("mouseleave", "industry-pointer", () => {
      map.getCanvas().style.cursor = "";
    });

    // inspect a cluster on click
    map.on("click", "clusters", function(e) {
      var features = map.queryRenderedFeatures(e.point, {
        layers: ["clusters"]
      });
      var clusterId = features[0].properties.cluster_id;
      map
        .getSource("industry-data")
        .getClusterExpansionZoom(clusterId, function(err, zoom) {
          if (err) return;

          map.easeTo({
            center: features[0].geometry.coordinates,
            zoom: zoom
          });
        });
    });

    // When a click event occurs on a feature in
    // the unclustered-point layer, open a popup at
    // the location of the feature, with
    // description HTML from its properties.
    map.on("click", "unclustered-point", function(e) {
      const feature = e.features[0];
      var coordinates = feature.geometry.coordinates.slice();

      // Ensure that if the map is zoomed out such that
      // multiple copies of the feature are visible, the
      // popup appears over the copy being pointed to.
      while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
      }

      const popupNode = document.createElement("div");
      ReactDOM.render(<Popup feature={feature} />, popupNode);
      // set popup on map
      popUpRef.current
        .setLngLat(feature.geometry.coordinates)
        .setDOMContent(popupNode)
        .setMaxWidth("350px")
        .addTo(map);
    });

    // clean up on unmount
    return () => map.remove();
  };

  const loadMapMarkers = async (map, mapData) => {
    const results = await fetchMap(mapData);
    map.getSource("industry-data").setData(results);
  };

  const filterAction = filter => {
    const filterUrl = `https://www.mapa.profissaobiotec.com.br/api/get-info.php?nome=${filter.nome}&ano_inicio=${filter.ano.min}&ano_fim=${filter.ano.max}&porte=${filter.porte}&tipo=${filter.tipo}&setor=${filter.setor}&estado=${filter.estado}&cidade=${filter.cidade}&regiao=${filter.regiao}`;
    fetch(filterUrl)
      .then(res => res.json())
      .then(
        result => {
          if (result.length == 0) setNoResultsModal(true);
          console.log(result.length);
          loadMapMarkers(stateMap, result);
        },
        error => {
          console.log("erro: ", error);
        }
      );
  };

  return (
    <div className="App">
      <div className="map-container" ref={mapContainerRef} />
      <Filter filterAction={filterAction} />
      <Modal />
      <div>
        {noResultsModal && (
          <div className="modal">
            <div className="modal-no-results">
              <h3>Empresa não encontrada</h3>
              <p>Não encontramos nenhum resultado para a sua busca.</p>
              <button
                type="button"
                className="closeModal"
                onClick={() => setNoResultsModal(false)}
              >
                Fechar!
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MapBox;
